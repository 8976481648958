<template>
	<aside
		id="options"
		class="options"
		role="dialog"
		aria-labelledby="dialogTitle"
		aria-describedby="dialogDesc"
		:hidden="get_menu"
		:aria-hidden="`${get_menu}`"
		@click.self="toggle_menu()"
	>
		<div class="box">
			<div class="content">
				<div class="setting">
					<h6 id="dialogTitle" v-t="'common.settings.cookie.title'" />
					<p id="dialogDesc" v-t="'common.settings.cookie.text'" />
					<button
						:title="$t('common.settings.cookie.toggle')"
						class="toggle cookie"
						:class="is_active()"
						@click="cookie_consent()"
					>
						<font-awesome-icon
							:icon="['fal', `toggle-${is_active()}`]"
							size="3x"
							fixed-width
						/>
					</button>
				</div>
				<div class="setting">
					<div class="btn-container">
						<p class="lang-text">
							<font-awesome-icon :icon="['fal', 'flag-alt']" fixed-width />
							<span v-t="'common.settings.language_select'" />
						</p>
						<router-link
							v-for="language in get_languages"
							:key="language.key"
							class="btn"
							:lang="$t('common.lang-code', language)"
							:id="language"
							:to="{ name: $route.name, params: { lang: language } }"
						>
							<span v-t="$t('common.language', language)" />
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	computed: {
		...mapGetters(['get_languages', 'get_consent', 'get_menu'])
	},
	methods: {
		...mapActions(['cookie_consent', 'toggle_menu']),
		is_active() {
			return this.get_consent ? 'on' : 'off'
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.options {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	width: 100%;
	font-size: 85%;
	background: rgba(0, 0, 0, 0.75);

	&[aria-hidden='false'] {
		backdrop-filter: blur(10px);
		display: flex;
		align-items: center;
	}
	.box {
		color: $white;
		max-width: 1000px;
		width: 100%;
		text-align: left;
		margin: 0 auto;
		position: relative;
		.content {
			padding: 1em;
			bottom: 70px;
			.header {
				padding-bottom: 1em;
			}
		}
	}
	p {
		font-size: 50%;
		margin: 0;
	}
}

.toggle {
	color: $white;
	transition: $transition;
	border-radius: 15px;
	box-shadow: $shadow;
	margin-top: 15px;
	&.off {
		background: $fire;
		&:hover {
			color: darken($white, 5%);
		}
	}
	&.on {
		background: $grass;
		&:hover {
			color: darken($white, 5%);
		}
	}
	&:focus {
		outline: 0;
		box-shadow: 0 1px 5px;
	}
}

.setting {
	padding-bottom: 1em;
}

.btn-container {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	.btn {
		flex: 0 0 auto;
		padding: 1.5em 2em;
		border-radius: 15px;
		color: $white;
		font-size: 0.5em;
		font-family: $head-font;
		text-align: center;
		svg {
			margin-right: 5px;
		}
		&.active {
			background: $sky;
			box-shadow: $shadow;
		}
	}
	.lang-text {
		align-self: center;
		span {
			margin: 0 0.5rem 0 0.25rem;
		}
	}
}

@media (prefers-color-scheme: dark) {
	.toggle {
		&.off {
			background: $red;
		}
		&.on {
			background: $green;
		}
	}

	.btn-container {
		.btn {
			&.active {
				background: $spring;
				color: $black;
			}
		}
	}
}
</style>
